<template>
  <div class="Details">
    <div class="headers">
      <div class="Imgs">
        <div class="image-gallery">
          <div class="main-image-container">
            <img
              :src="bigMap.imageList[activeIndex].imageUrl"
              style="width: 100%; height: 100%"
              alt="Image"
            />
          </div>
          <div class="thumbnail-container">
            <div
              v-for="(image, index) in bigMap.imageList"
              :key="index"
              :class="{ items: true, active: activeIndex === index }"
              @click="updateActiveIndex(index)"
            >
              <img
                :src="image.imageUrl"
                alt="Thumbnail"
                style="height: 100%; width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bodys">
      <div class="bodyLeft">
        <div class="bigText">
          <div class="titKuang">
            <el-button
              type="primary"
              size="small"
              style="
                width: 100px;
                color: #000;
                font-weight: 600;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              "
              @click="gotoCreate"
              icon="el-icon-edit"
              >续写</el-button
            >
            <div class="text">
              已有续写 <span>{{ bigMap.renewNum }}</span
              >，续写深度 <span>{{ bigMap.level }}</span>
            </div>
            <div class="item">
              <img
                src="@/assets/images/dianzan.png"
                style="width: 20px; height: 20px; margin-right: 6px"
                alt="Image"
              />
              <span>点赞</span>
            </div>
            <span>{{ bigMap.favorNum }}</span>
            <div class="item">
              <img
                src="@/assets/images/shoucang.png"
                style="width: 20px; height: 20px; margin-right: 6px"
                alt="Image"
              />
              <span>收藏</span>
            </div>
            <span>{{ bigMap.collectNum }}</span>
            <div class="item">
              <img
                src="@/assets/images/songli.png"
                style="width: 20px; height: 20px; margin-right: 6px"
                alt="Image"
              />
              <span>送礼</span>
            </div>
            <span>{{ bigMap.giftNum }}</span>
            <div class="item">
              <img
                src="@/assets/images/pinglun.png"
                style="width: 20px; height: 20px; margin-right: 6px"
                alt="Image"
              />
              <span>评论</span>
            </div>
            <span>{{ bigMap.commentNum }}</span>
          </div>
          <div class="name">
            <div class="tit">{{ bigMap.title }}</div>
            <div class="nums">
              <span style="color: #999">作品收益:</span>
              <div class="money">
                <div>{{ bigMap.incomeEthNum }}</div>
                <img
                  style="width: 26px; margin-left: 8px"
                  src="@/assets/images/ethBig.png"
                  alt=""
                />
              </div>
              <div class="money">
                <div>{{ bigMap.incomePlatformCurrencyNum }}</div>
                <img
                  style="width: 26px; margin-left: 8px"
                  src="@/components/Header/images/btc.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="userBox">
            <div class="user">
              <el-avatar
                shape="square"
                size="medium"
                :src="bigMap.authorHeadImage"
              />
              <span class="nickName">{{ bigMap.authorName }}</span>
              <img
                style="margin-left: 12px"
                src="@/assets/images/kaipianren.png"
                alt=""
              />
            </div>
            <div class="date">发布于：{{ bigMap.publishDate }}</div>
          </div>
          <div class="content">
            {{ bigMap.content }}
          </div>
        </div>

        <div class="navigation">
          <div class="top">
            <div class="pages">
              第1层续写，共计 {{ bigMap.childArticleList.length }} 篇作品
            </div>
            <div class="pageNum">
              第1页，4/ {{ bigMap.childArticleList.length }}
            </div>
          </div>
          <div class="datas">
            <el-button
              icon="el-icon-caret-left "
              class="datasButton butLeft"
              type="text"
            ></el-button>
            <el-button
              icon="el-icon-caret-right "
              class="datasButton butRight"
              type="text"
            ></el-button>
            <div
              class="constItem"
              v-for="item in bigMap.childArticleList"
              :key="item.articleId"
            >
              <div class="img">
                <el-image
                  style="width: 100%; height: 100%; object-fit: cover"
                  :src="item.imageUrl"
                  fit="cover"
                ></el-image>
                <div class="title">{{ item.title }}</div>
              </div>

              <div class="center">
                <div class="likeItem">
                  <img
                    style="width: 16px; margin-right: 6px"
                    src="@/pages/myWork/images/write.png"
                  />
                  <span> {{ item.renewNum }}</span>
                </div>
                <div class="likeItem">
                  <img
                    style="width: 16px; margin-right: 6px"
                    src="@/pages/myWork/images/like.png"
                  />
                  <span> {{ item.favorNum }}</span>
                </div>
              </div>

              <div class="bottom">
                <el-avatar
                  shape="square"
                  size="small"
                  :src="item.authorHeadImage"
                />
                <span class="nickName">{{ item.authorName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bodyRight">
        <div class="rightTit">
          <img
            src="@/assets/images/fire.png"
            style="width: 24px; height: 24px; margin-right: 6px"
            alt="Image"
          />
          <span>热门续写</span>
        </div>
        <div class="rightContent">
          <div
            class="constItem"
            v-for="item in bigMap.hotArticleList"
            :key="item.articleId"
          >
            <div class="img">
              <el-image
                style="width: 100%; height: 100%; object-fit: cover"
                :src="item.imageUrl"
                fit="cover"
              ></el-image>
              <div class="title">{{ item.title }}</div>
            </div>

            <div class="center">
              <div class="likeItem">
                <img
                  style="width: 16px; margin-right: 6px"
                  src="@/pages/myWork/images/write.png"
                />
                <span> {{ item.renewNum }}</span>
              </div>
              <div class="likeItem">
                <img
                  style="width: 16px; margin-right: 6px"
                  src="@/pages/myWork/images/like.png"
                />
                <span> {{ item.favorNum }}</span>
              </div>
            </div>

            <div class="bottom">
              <el-avatar
                shape="square"
                size="small"
                :src="item.authorHeadImage"
              />
              <span class="nickName">{{ item.authorName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { detail } from "@/api/article";
import { mapState } from "vuex";
import { modifyInfo } from "@/api";
export default {
  data() {
    return {
      bigMap: {
        imageList: [
          {
            imageUrl: "", //图片地址
            isMain: "", //是否为主图 1是 0否
          },
        ],
        articleId: "", //作品ID
        publishDate: "", //发布时间
        articleType: "", //作品类型，0开篇，1续写
        level: "", //续写深度
        renewNum: "", //续写数
        favorNum: "", //点赞数
        collectNum: "", //收藏数
        giftNum: "", //送礼数
        commentNum: "", //评论数
        title: "", //标题
        incomeEthNum: "", //以太坊的收益
        incomePlatformCurrencyNum: "", //平台的收益
        authorName: "", //作者名称
        authorHeadImage: "", //作者头像
        authorAccountId: "", //作者ID
        authType: "", //权限 1=所有权限 2= 可查看 3= 不可点击
        content: "", //作品内容
        accountId: "", //当前登录ID
        contentType: "", //内容类型 1 图片 2文本
        childArticleList: [
          {
            articleId: "",
          },
        ], //续写作品列表
        childArticleNum: "", //续写作品数量
        hotArticleList: [
          {
            articleId: "",
          },
        ], //热门作品列表

        isCollect: "", //是否收藏 1=是 0=否

        isFavor: "", //是否点赞 1=是 0=否
      },
      // 选中的图片id
      activeIndex: 0,

    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    gotoCreate() {
      // 判断是否登录
      if (!this.accountId) {
        return this.$message("请先登录");
      }
      // 判断是否有昵称
      if (this.userInfo.nickName) {
        this.$router.push({
          path: "/Create",
          // 存创作类型
          query: {
            type: 2,
          },
        });
      } else {
        this.$prompt("请输入昵称", "你需要先设置一个昵称:", {
          confirmButtonText: "提交",
          inputPattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/,
          inputErrorMessage: "昵称格式不符合要求，请修改后提交",
        }).then(({ value }) => {
               let param ={
            accountId:this.accountId,
            userName: value
          }
          modifyInfo(param).then(({ success }) => {
            if (success) {
              this.$store.dispatch("getUserInfo");
            }
          });
        });
      }
    },
    getData() {
      let param = {
        articleId: this.agoId,
        accountId: this.accountId || '',
      };
      detail(param).then(({ code, data }) => {
        console.log(data);
        if (code == 1000) {
          this.bigMap = data;
        }
      });
    },
    updateActiveIndex(index) {
      this.activeIndex = index;
    },
  },
  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
      userInfo: (state) => state.user.userInfo,
      agoId: (state) => state.page.agoId,
    }),
  },
};
</script>

<style scoped lang="less">
.Details {
  min-height: 90vh;
  .headers {
    width: 100%;
    height: 560px;
    background-color: #16191e;
    .Imgs {
      width: 1200px;
      margin: 0 auto;
      height: 100%;

      .image-gallery {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 20px 0;
        .main-image-container {
          flex: 1;
          height: 100%;
          margin-right: 30px;
        }
        .thumbnail-container::-webkit-scrollbar {
          width: 6px;
        }
        .thumbnail-container::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(249, 249, 249, 0.2);
        }
        .thumbnail-container::-webkit-scrollbar-track {
          border-radius: 0;
          background: rgba(249, 249, 249, 0.1);
        }
        .thumbnail-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          width: 252px;
          overflow-y: auto;
          // overflow-x: hidden;

          .items {
            box-sizing: border-box;
            height: 32%;
            width: 246px;
            opacity: 50%;
            margin-bottom: 8px;
          }
          .active {
            border: 2px solid #01e099;
            opacity: 1;
          }
        }
      }
    }
  }
  .bodys {
    display: flex;
    width: 1200px;
    margin: 30px auto;
    .bodyLeft {
      flex: 1;
      margin-right: 30px;

      .bigText {
        box-sizing: border-box;
        width: 100%;
        padding: 20px;
        background-color: #16191e;
        border: 1px solid #666;
        border-radius: 8px;
        .titKuang {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          height: 60px;
          width: 101%;
          margin: 8px -10px 18px -10px;
          align-items: center;
          border-radius: 30px;
          padding: 0 20px;
          background: #000;
          color: #fffbfb;
          .text {
            span {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .item {
            width: 80px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #d7d7d7;
            color: #000;
            border-radius: 4px;
          }
        }
        .name {
          display: flex;
          .tit {
            flex: 1;
            font-size: 24px;
          }
          .nums {
            width: 280px;
            justify-content: space-between;
            display: flex;
            align-items: center;
            .money {
              display: flex;
              align-items: center;
            }
          }
        }
        .userBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0 30px;
          .user {
            display: flex;
            align-items: center;
            padding-left: 10px;
            .nickName {
              margin-left: 16px;
            }
            color: #ccc;
          }
          .date {
            color: #828282;
          }
        }
        .content {
          font-size: 16px;
          line-height: 2em;
          padding-bottom: 20px;
        }
      }
      .navigation {
        box-sizing: border-box;
        width: 100%;
        height: 250px;
        padding: 8px 20px;
        margin: 20px 0;
        background: linear-gradient(90deg, #dc4b98 0%, #ffad5c 99%);
        border-radius: 4px;
        // opacity: 0.9;

        .top {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .pages {
            font-size: 18px;
          }
          .pageNum {
            font-size: 12px;
          }
        }
        .datas {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          .datasButton {
            color: #fff;
            font-size: 50px;
          }
          .butLeft {
            position: absolute;
            top: 60px;
            z-index: 999;
            left: -20px;
          }
          .butRight {
            position: absolute;
            top: 60px;
            z-index: 999;
            right: -20px;
          }

          .constItem {
            box-sizing: border-box;
            width: 24%;
            margin-bottom: 20px;
            background: #18191d;
            .img {
              width: 100%;
              height: 120px;
              position: relative;
              .title {
                font-size: 14px;
                line-height: 30px;
                position: absolute;
                bottom: 0;
                font-weight: 600;
                padding-left: 6px;
                background-color: rgba(0, 0, 0, 0.5);
              }
            }

            .center {
              display: flex;
              align-items: center;
              padding: 4px 0;
              font-size: 12px;
              color: #646464;
              .likeItem {
                display: flex;
                align-items: center;
                margin-left: 8px;
              }
            }
            .bottom {
              width: 100%;
              display: flex;
              align-items: center;
              color: #ccc;
              padding: 6px;

              .nickName {
                margin-left: 16px;
              }
            }
          }
        }
      }
    }
    .bodyRight {
      width: 252px;
      .rightTit {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        border-radius: 4px;
        background: linear-gradient(90deg, #dc4b98 0%, #ffad5c 99%);
        font-weight: 600;
      }
      .rightContent {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;

        .constItem {
          box-sizing: border-box;
          width: 100%;
          margin-bottom: 20px;
          background: #18191d;
          .img {
            width: 100%;
            height: 120px;
            position: relative;
            .title {
              font-size: 14px;
              line-height: 30px;
              position: absolute;
              bottom: 0;
              font-weight: 600;
              padding-left: 6px;
              background-color: rgba(0, 0, 0, 0.5);
            }
          }

          .center {
            display: flex;
            align-items: center;
            padding: 4px 0;
            font-size: 12px;
            color: #646464;
            .likeItem {
              display: flex;
              align-items: center;
              margin-left: 8px;
            }
          }
          .bottom {
            width: 100%;
            display: flex;
            align-items: center;
            color: #ccc;
            padding: 6px;

            .nickName {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
